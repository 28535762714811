<template>
  <resource-model v-slot="model">
    <resource-view v-slot="view" @updated="setData">
      <!-- Item Show -->
      <section class="content__container item__show" :class="{ loading }">
        <!-- Item Header -->
        <item-header :view="view" :abilities="model['abilities']" />
        <!-- Custom Buttons -->
        <resource-buttons v-slot="buttons" group="list">
          <div class="d-flex mb-3" style="gap: 1rem">
            <b-button
              :key="index"
              :variant="button.variant"
              :size="button.size || 'sm'"
              v-for="(button, index) in buttons"
              @click="onCustomButtonClick(button)"
              v-b-tooltip.bottom.hover="button.tooltip"
              v-show="button.admin_only ? isAdmin : true"
            >
              <i v-if="button.icon" :class="`fal fa-${button.icon} mr-1`" />
              <span v-if="button.label">{{ button.label }}</span>
            </b-button>
          </div>
        </resource-buttons>
        <!-- Vertical Data Grid -->
        <data-table-vert
          :data="data"
          :html="view['html']"
          class="content__body"
        />
        <!-- Alert -->
        <app-alert v-bind="alert" @close="alert = {}" v-if="_.some(alert)" />
      </section>
    </resource-view>
  </resource-model>
</template>

<script>
import DataTableVert from "@/components/data/DataTableVert";
import ItemHeader from "@/components/item/ItemHeader";
export default {
  components: {
    DataTableVert,
    ItemHeader,
  },
  data() {
    return {
      data: {},
      loading: false,
    };
  },
  beforeMount() {
    this.setData();
  },
  computed: {
    viewPayload() {
      return this.$hasResourceVariantQuery() ? { view: true } : {};
    },
  },
  methods: {
    setData() {
      this.loading = true;
      this.alert = {};
      this.data = {};
      this.$http
        .get(this.$resource.getApiPath(), {
          params: {
            ...this.viewPayload,
            ...this.$route["query"],
          },
        })
        .then(({ data: { data } }) => (this.data = data))
        .finally(() => (this.loading = false));
    },
    onCustomButtonClick(button) {
      const action = {
        resource: this.$route.params.resource,
        data: this.data,
        name: {
          route: button["action"],
          method: button["method"],
          params: button["params"],
          target: button["target"],
          query: button["query"],
        },
      };
      this.$handleCustomButtonAction(action, (data) => {
        this.alert = data;
        this.setData();
      });
    },
  },
};
</script>