<script>
export default {
  methods: {
    $handleDefaultButtonAction(action) {
      let name = action["name"];
      let identity = action["value"];
      let resource = action['resource'];
      let params = name == "create" ? { resource } : { resource, identity };
      this.$router.push({ name, params, query: this.$route["query"] });
    },
    $handleCustomButtonAction(action, callback) {
      var { route, method, params, target, query } = action["name"];
      var payload = {};
      _.forEach(params, (v, k) => (payload[k] = _.get(action["data"], v, v)));
      if (_.isEmpty(params)) payload = action["value"];
      if (["POST", "PUT", "DELETE"].includes(method)) {
        payload = !_.isObject(payload) ? [payload] : payload;
        this.$http({
          url: route,
          method,
          data: { ...payload, ...query },
        }).then(({ data: { data } }) => {
          if (_.isFunction(callback)) {
            callback(data);
          }
        });
      } else {
        var filter = {};
        _.forEach(query, (v, k) => (filter[k] = _.get(action["data"], v, v)));
        // query = { ...filter }; // + ...query ? => default query?
        route = { name: route, params: payload, query: filter };
        target
          ? window.open(this.$route2url(route), target)
          : this.$router.push(route);
      }
    },
  },
};
</script>
