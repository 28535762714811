<template>
  <form method="post" @submit.prevent="login">
    <template v-if="uuid">
      <!-- UUID Logging in -->
      <b-alert variant="info" :show="uuid && loading" class="mb-0">
        <i class="fal fa-spin fa-spinner mr-1" />
        {{ __("U wordt nu ingelogd... | You are being logged in...") }}
      </b-alert>
      <!-- UUID Login Error -->
      <b-alert variant="danger" :show="!_.isEmpty(message)" class="mb-0">
        <i class="fal fa-frown mr-1" /> {{ message }}
      </b-alert>
    </template>
    <template v-else>
      <!-- Username -->
      <form-field
        v-model="username"
        :errors="errors['username']"
        class="d-block mb-3"
        :auth="true"
        :field="{
          name: 'username',
          label: __('Uw gebruikersnaam/e-mailadres: | Your username/email:'),
          placeholder: __(
            'Voer uw gebruikersnaam of e-mailadres in | Enter your username or email'
          ),
          control: 'input',
          icon: 'user',
          autofocus: true,
          required: true,
          class: 'text-center',
        }"
      />
      <!-- Password -->
      <form-field
        v-model="password"
        :errors="errors['password']"
        class="d-block mb-3"
        :auth="true"
        :field="{
          name: 'password',
          label: __('Uw wachtwoord: | Your password:'),
          placeholder: __('Voer uw wachtwoord in | Enter your password'),
          control: 'input',
          type: 'password',
          required: true,
          icon: 'key',
          class: 'text-center',
        }"
      />
      <!-- Footer -->
      <div class="d-flex justify-content-between align-content-center mt-3">
        <!-- Remember -->
        <b-form-checkbox
          class="text-secondary mb-0"
          :unchecked-value="false"
          v-model="remember"
          name="remember"
        >
          {{ __("Onthoud mij | Remember me") }}
        </b-form-checkbox>
        <!-- Forgot -->
        <router-link :to="forgotRoute">
          {{ __("Wachtwoord vergeten? | Forgot your password?") }}
        </router-link>
      </div>
      <!-- Button -->
      <submit-button :loading="loading" class="mt-3" @submit="login">
        <i class="fal fa-lock mr-1" />
        {{ __("Inloggen | Login") }}
      </submit-button>
    </template>
  </form>
</template>

<script>
import SubmitButton from "@/components/form/SubmitButton";
import FormField from "@/components/form/FormField";
import AuthService from "@/services/AuthService";
import AppForm from "@/components/app/AppForm";
export default {
  components: {
    SubmitButton,
    FormField,
    AppForm,
  },
  data() {
    return {
      user: {},
      fields: {},
      errors: {},
      message: null,
      loading: false,
      username: null,
      password: null,
      remember: false,
    };
  },
  beforeMount() {
    this.redirectIfLoggedIn();
    this.redirectLoginByUuid();
  },
  computed: {
    nextRoute() {
      let redirect = _.get(this.$route.query, "redirect");
      let homePath = _.get(this.user, "home_path");
      return redirect || homePath || "/";
    },
    forgotRoute() {
      const query = this.username ? { username: this.username } : {};
      return { name: "forgot", query };
    },
    uuid() {
      return this.$route.query["uuid"];
    },
  },
  methods: {
    login() {
      this.errors = {};
      this.loading = true;
      AuthService.login({
        username: this.username,
        password: this.password,
        remember: this.remember,
        lang: this.$lang(),
      })
        .then(async ({ data: { two_factor } }) => {
          if (this.$isRoot() && !two_factor) {
            this.$router.push("module");
          } else if (two_factor) {
            this.$router.push("two_factor");
          } else {
            this.user = await this.getUser();
            this.redirectUser();
          }
        })
        .catch(({ response: { data } }) => {
          this.message = data.message;
          this.errors = data.errors;
        })
        .finally(() => (this.loading = false));
    },
    async getUser() {
      const response = await AuthService.user();
      return _.get(response, "data.data");
    },
    async redirectIfLoggedIn() {
      this.user = await this.getUser();
      if (this.user) this.redirectUser();
    },
    redirectUser(route = this.nextRoute) {
      const { modules } = this.user;
      const allowed = _.map(modules, "host");
      if (allowed.includes(window.location.hostname)) {
        this.$router.push(route);
      } else if (allowed.length === 1) {
        window.location.href = `//${allowed[0]}`;
      } else if (allowed.length > 1) {
        this.$router.push({ name: "module" });
      }
    },
    redirectLoginByUuid() {
      if (this.uuid) {
        this.username = this.uuid;
        this.password = this.uuid;
        this.remember = true;
        this.login();
      }
    },
  },
};
</script>