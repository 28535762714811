<template>
  <section :class="getWrapperClass(view)">
    <!-- Data Component -->
    <component
      v-show="!_.isNull(data)"
      :is="componentName"
      ref="dataComponent"
      v-model="selected"
      :filters="filterOptions"
      :abilities="abilities"
      :keywords="keywords"
      :loading="loading"
      :data="data"
      :meta="meta"
      :view="view"
      @selection="onSelection"
      @reorder="onReorder"
      @action="onAction"
      @select="onSelect"
      @filter="onFilter"
      @search="onSearch"
      @sort="onSort"
      class="fadeIn"
    >
      <template slot="header">
        <slot name="header">
          <!-- Default Header -->
          <index-header
            v-if="header"
            :view="view"
            :abilities="abilities"
            :filters="filterOptions"
            @sort="onSort"
            @reload="setData"
            @search="onSearch"
            @filter="onFilter"
          />
        </slot>
      </template>
      <template slot="footer">
        <slot name="footer">
          <!-- Default Footer -->
          <index-footer
            class="index__footer"
            :loading="loading"
            :status="status"
            :limit="limit"
            :error="error"
            :meta="meta"
            @reload="setData"
            v-if="footer"
          />
        </slot>
      </template>
    </component>
    <!-- Alert -->
    <app-alert v-bind="alert" @close="alert = {}" v-if="_.some(alert)" />
  </section>
</template>

<script>
import IndexFooter from "@/components/index/IndexFooter";
import IndexHeader from "@/components/index/IndexHeader";
import DataBrowser from "@/components/data/DataBrowser";
import DataTable from "@/components/data/DataTable";
import DataCards from "@/components/data/DataCards";
import DataGrid from "@/components/data/DataGrid";
import AppAlert from "@/components/app/AppAlert";
import { mapGetters } from "vuex";
export default {
  inheritAttrs: false,
  components: {
    IndexFooter,
    IndexHeader,
    DataBrowser,
    DataTable,
    DataCards,
    DataGrid,
    AppAlert,
  },
  props: {
    view: {
      type: Object,
      default: () => ({
        layout: "grid",
      }),
    },
    header: {
      type: Boolean,
      default: true,
    },
    footer: {
      type: Boolean,
      default: true,
    },
    request: {
      type: Object,
      default: () => ({}),
      required: true,
    },
  },
  data() {
    return {
      data: null,
      meta: {},
      page: 1,
      alert: {},
      sorts: {},
      error: false,
      limit: 25,
      filter: null,
      status: null,
      update: false,
      search: [],
      filters: [],
      loading: false,
      selected: {},
      keywords: null,
      deviceComponents: {
        phone: "cards",
        tablet: "table",
        desktop: "browser",
      },
    };
  },
  beforeMount() {
    this.getFilters((filters) => {
      this.setFilters(filters);
      this.setData();
    });
  },
  watch: {
    selected({ name }) {
      if (this.isPrimary) {
        document.title = `${name} - ${this.view["title"]} · PropertyView`;
      }
    },
  },
  // activated() {
  // always refresh secondary data
  // if (!this.isPrimary) {
  // this.resetData();
  // }
  // },
  computed: {
    resource() {
      return _.trim(this.request["url"], "/");
    },
    abilities() {
      return _.get(this.meta, "model.abilities", {});
    },
    primaryKey() {
      return _.get(this.meta, "model.primary_key", null);
    },
    isPrimary() {
      return this.resource === this.$route.params["resource"];
    },
    nextRoute() {
      const { navigate, update, view } = this.abilities;
      if (navigate && !this.isGridLike) {
        return "menu";
      } else if (update) {
        return "edit";
      } else if (view) {
        return "show";
      }
    },
    routePayload() {
      return this.$route["query"];
    },
    viewPayload() {
      return this.$hasResourceVariantQuery() ? { view: true } : {};
    },
    pagePayload() {
      return {
        "page[size]": this.limit,
        "page[number]": this.page,
      };
    },
    filterPayload() {
      return this.filter
        ? this.$queryParamsToObject(this.filter, "filter")
        : {};
    },
    contextPayload() {
      return this.$objectToQueryParams(this.$context(), "context");
    },
    filterOptions() {
      return _.map(this.filters, ({ label, value, selected }) => {
        return { text: label, value, selected };
      });
    },
    componentName() {
      let component = this.view["layout"];
      if (this.$getDevice() !== "desktop" || !this.view["layout"]) {
        component = _.get(this.deviceComponents, this.$getDevice());
      } else if (this.isPrimary) {
        component = this.authUser["layout_index"];
      }
      return `data-${component.replace("data-", "")}`;
    },
    isGridLike() {
      return ["data-browser", "data-grid"].includes(this.componentName);
    },
    defaultActions() {
      return ["show", "create", "edit", "delete"];
    },
    ...mapGetters("auth", ["authUser"]),
  },
  methods: {
    setData(customPayload = {}) {
      this.error = false;
      this.loading = true;
      this.getData(customPayload, ({ data, meta, links }) => {
        this.data = data;
        this.meta = meta;
        this.links = links;
        this.loading = false;
        this.limit = meta["per_page"];
        this.page = meta["current_page"];
        this.optionallySelectSingleRow();
      });
    },
    getData(customPayload = {}, callback) {
      this.$http(this.getApiRequest(customPayload))
        .then(({ data }) => callback(data))
        .catch(() => (this.error = true));
    },
    getApiRequest(customPayload) {
      return _.merge(
        {
          params: {
            ...this.getSearchPayload(),
            ...this.getSortPayload(),
            ...this.contextPayload,
            ...this.filterPayload,
            ...this.viewPayload,
            ...this.pagePayload,
            ...this.routePayload,
            ...customPayload,
          },
        },
        _.cloneDeep(this.request)
      );
    },
    resetData() {
      this.page = 1;
      this.data = [];
      this.limit = 25;
      this.setData();
    },
    getFilters(callback) {
      this.$http
        .get(`${this.resource}/filters`, { params: this.request["params"] })
        .then(({ data: { data } }) => callback(data))
        .catch(() => (this.error = true));
    },
    setFilters(filters) {
      this.filters = filters;
      _.forEach(filters, ({ selected, value }) => {
        if (selected) {
          this.filter = value;
          return;
        }
      });
    },
    getSearchPayload() {
      let payload = { keywords: this.keywords };
      _.forEach(this.search, function ({ column, operator, keywords }) {
        if (column && operator && keywords) {
          payload[`filter[${column}]`] = keywords;
          payload[`operator[${column}]`] = operator;
        }
      });
      return payload;
    },
    getSortPayload() {
      const columns = _.map(this.sorts, (direction, column) => {
        if (direction) {
          let prepend = direction === "desc" ? "-" : "";
          return prepend + column;
        }
      });
      if (_.isEmpty(columns)) return {};
      return { sort: columns.join(",") };
    },
    onSearch(search) {
      this.page = 1;
      this.search = search;
      if (_.isNull(search)) {
        // no search
        this.search = null;
        this.keywords = null;
      } else if (_.isString(search)) {
        // use global search
        this.search = null;
        this.keywords = search;
      } else if (_.isObject(search)) {
        // use scoped search
        this.search = search;
        this.keywords = null;
        _.forEach(search, ({ column, operator, keywords }) => {
          // check for unspecified column in query and wildcard operator
          if (_.isNull(column) && operator == 6) {
            // add a global search (additionally)
            this.keywords = keywords;
          }
        });
      }
      this.setData();
    },
    onFilter(filter) {
      this.filter = filter;
      this.setData();
    },
    onSort(sorts) {
      this.sorts = sorts;
      this.setData();
    },
    onReorder(ids) {
      this.$http.put(`${this.request["url"]}/reorder`, { ids });
    },
    onSelection(selection) {
      if (_.get(this.view, "selection") == "multiple") {
        let count = _.size(selection);
        let size = count == 1 ? "singular" : "plural";
        let noun = _.get(this.meta, `model.${size}`);
        let verb = this.__("index.footer.selected");
        this.status = `${count} ${noun} ${verb}`;
      }
    },
    onSelect({ __data: { value } }) {
      if (this.nextRoute && value) {
        this.$router.push({
          name: this.nextRoute,
          query: {
            ...this.request["params"],
            ...this.$route["query"],
          },
          params: {
            resource: this.resource,
            identity: value,
          },
        });
      }
    },
    onAction(action) {
      if (_.isString(action["name"])) {
        action['resource'] = this.resource;
        this.$listeners["action"]
          ? this.$emit("action", action)
          : this.$handleDefaultButtonAction(action);
      } else if (_.isObject(action["name"])) {
        this.$handleCustomButtonAction(action, (data) => {
          this.alert = data;
          this.setData();
        });
      }
    },
    // useDefaultAction(action) {
    //   let name = action["name"];
    //   let identity = action["value"];
    //   let resource = this.resource;
    //   let params = name == "create" ? { resource } : { resource, identity };
    //   this.$router.push({ name, params, query: this.$route["query"] });
    // },
    // useCustomAction(action) { 
    //   var { route, method, params, target, query } = action["name"];
    //   var payload = {};
    //   _.forEach(params, (v, k) => (payload[k] = _.get(action["data"], v, v)));
    //   if (_.isEmpty(params)) payload = action["value"];
    //   if (["POST", "PUT", "DELETE"].includes(method)) {
    //     payload = !_.isObject(payload) ? [payload] : payload;
    //     this.$http({ url: route, method, data: { ...payload, ...query } })
    //       .then(({ data: { data } }) => (this.alert = data))
    //       .finally(() => this.setData());
    //   } else {
    //     var filter = {};
    //     _.forEach(query, (v, k) => (filter[k] = _.get(action["data"], v, v)));
    //     query = { ...filter, ...this.$route["query"] }; // + ...query ? => default query?
    //     route = { name: route, params: payload, query };
    //     target
    //       ? window.open(this.$route2url(route), target)
    //       : this.$router.push(route);
    //   }
    // },
    getWrapperClass(view) {
      let componentClass = this.componentName.replace("-", "--");
      let emptyViewClass = _.isEmpty(view) ? "no-view" : "";
      let isLoadingClass = this.loading ? "loading" : "";
      return `${componentClass} ${emptyViewClass} ${isLoadingClass}`;
    },
    optionallySelectSingleRow() {
      return;
      if (_.size(this.data) === 1) {
        this.onSelect(_.first(this.data));
      }
    },
  },
};
</script>